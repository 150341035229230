import nlBeLocale from "date-fns/locale/nl-BE";
import frLocale from "date-fns/locale/fr";
import enGbLocale from "date-fns/locale/en-GB";

export const LANGUAGES = ['EN', 'NL', 'FR'];

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

export const ROWS_PER_PAGE_SELECT = (value) => {
	if (!value) {
		return ROWS_PER_PAGE_OPTIONS.at(0);
	}

	// select the nearest ROW_PER_PAGE_OPTIONS for given value
	let i = 0;
	while (i < ROWS_PER_PAGE_OPTIONS.length && ROWS_PER_PAGE_OPTIONS[i] < value) {
		i++;
	}
	if (i < ROWS_PER_PAGE_OPTIONS.length) {
		return ROWS_PER_PAGE_OPTIONS[i];
	} else {
		return ROWS_PER_PAGE_OPTIONS[ROWS_PER_PAGE_OPTIONS.length - 1];
	}
}

export const MAP_DATE_FNS_LOCALE = (sessionInfo) => {
	// utility to provide the correct locale. Note: localized import of x-date-pickers is not sufficient
	switch (sessionInfo?.language) {
		case "NL":
			return nlBeLocale;
		case "FR":
			return frLocale;
		default:
			return enGbLocale;
	}
}
