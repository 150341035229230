import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Box, Paper, Tab, Tabs} from "@mui/material";
import {withTranslation} from "react-i18next";
import AppContainer from "../common/AppContainer";
import CompanyApiKeyExpiryKeyOverviewComponent from "./CompanyApiKeyExpiryKeyOverviewComponent";
import CompanyIdpComponent from "./CompanyIdpComponent";
import CompanyOidcLoginComponent from "./CompanyOidcLoginComponent";
import CompanyStyleSettingsComponent from "./CompanyStyleSettingsComponent";

const TAB_API = {key: 'API', translateText: (props) => props.t('company.settingsTab_API')};
const TAB_IDP = {key: 'IDP', translateText: (props) => props.t('company.settingsTab_IDP')};
const TAB_STYLE = {key: 'SYLE', translateText: (props) => props.t('company.settingsTab_STYLE')};
const TAB_OIDC_LOGIN = {key: 'OIDC_LOGIN', translateText: (props) => props.t('company.settingsTab_OIDC_LOGIN')};

class CompanySettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			tab: null
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.tab && this.props.eligibleTabs.length > 0) {
			this.setState({tab: this.props.eligibleTabs.at(0)});
		}
	}

	render() {
		return <AppContainer withMenu needsSession>
			<Paper variant="outlined" sx={{p: {xs: 2, md: 3}}}>
				{this.state.tab && <Fragment>
					<Tabs value={this.state.tab} onChange={this.onChangeTab} variant="scrollable" scrollButtons>
						{this.props.eligibleTabs.map(tab => <Tab key={tab.key} value={tab} label={tab.translateText(this.props)}/>)}
					</Tabs>
					<Box sx={{mt: 2}}>
						{this.renderCurrentTab()}
					</Box>
				</Fragment>}
			</Paper>
		</AppContainer>
	}

	renderCurrentTab = () => {
		switch (this.state.tab) {
			case TAB_API:
				return <CompanyApiKeyExpiryKeyOverviewComponent/>;
			case TAB_IDP:
				return <CompanyIdpComponent/>;
			case TAB_STYLE:
				return <CompanyStyleSettingsComponent />;
			case TAB_OIDC_LOGIN:
				return <CompanyOidcLoginComponent/>;
		}
	}

	onChangeTab = (e, tab) => {
		this.setState({ tab });
	}

}

export default withTranslation()(connect(
	state => {
		const eligibleTabs = [];
		if (state.session?.info?.companySettingsAccessible) {
			eligibleTabs.push(TAB_API);
			eligibleTabs.push(TAB_IDP);
			eligibleTabs.push(TAB_STYLE);
			eligibleTabs.push(TAB_OIDC_LOGIN);
		}
		return {
			sessionInfo: state.session.info,
			eligibleTabs
		}
	},
	dispatch => {
		return {
		}
	}
)(CompanySettingsComponent));
